// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-es-hotel-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/templates/es/hotel.js" /* webpackChunkName: "component---src-templates-es-hotel-js" */),
  "component---src-templates-en-hotel-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/templates/en/hotel.js" /* webpackChunkName: "component---src-templates-en-hotel-js" */),
  "component---src-templates-es-location-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/templates/es/location.js" /* webpackChunkName: "component---src-templates-es-location-js" */),
  "component---src-templates-en-location-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/templates/en/location.js" /* webpackChunkName: "component---src-templates-en-location-js" */),
  "component---src-pages-about-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-en-about-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-index-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-search-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-es-about-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/es/about.js" /* webpackChunkName: "component---src-pages-es-about-js" */),
  "component---src-pages-es-index-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-hotel-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/hotel.js" /* webpackChunkName: "component---src-pages-hotel-js" */),
  "component---src-pages-index-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */)
}

exports.data = () => import("/Users/RG/Dropbox/Projects/hotels/grez-beta/.cache/data.json")

