module.exports = [{
      plugin: require('/Users/RG/Dropbox/Projects/hotels/grez-beta/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/RG/Dropbox/Projects/hotels/grez-beta/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/RG/Dropbox/Projects/hotels/grez-beta/node_modules/gatsby-plugin-typography/gatsby-browser'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    }]
